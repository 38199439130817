import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
    },
});

const useStyles = makeStyles({
    tableCell: {
        padding: "0px 0px 0px 0px",
        margin: 0
    }
});

function CreditCancelTable({ data }) {
    if (data.length == 0) {
        return (<></>);
    }

    const classes = useStyles();
    const t = [];

    Object.keys(data).forEach(key => (
        t.push(<TableRow key={key}>
            <TableCell align="left">{
                key === 'capitalade' ? 'Capital adeudado' :
                    key === 'capitalapa' ? '(+) Capital a pagar incluido en saldo pendiente' :
                        key === 'totalsal' ? '(-) Total saldo pendiente' :
                            key === 'totalade' ? '(-) Total adelantado no aplicado' :
                                key === 'montocanc' ? 'Monto para cancelar el crédito' : ''}
            </TableCell>
            <TableCell align="right" style={{ color: (key === 'totalsal' || key === 'totalade') ? 'red' : 'black' }}>{(key === 'totalsal' || key === 'totalade' ? '-' : '') + data[key]}</TableCell>
        </TableRow>)
    ));

    return (
        <Paper>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCell} align="left" style={{ width: '70%', paddingLeft: '1rem' }}>DESCRIPCIÓN</TableCell>
                        <TableCell className={classes.tableCell} align="center" style={{ width: '30%' }}>MONTO</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {t}
                </TableBody>
            </Table>
        </Paper>
    );
}

export default withStyles(styles)(CreditCancelTable);
