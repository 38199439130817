import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useEffect, useState } from "react";

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
  },
});

let id = 0;
function createData(cuota, fecha, descripcion, capital, interes, mora, multa, iva, cargos, total, saldo) {
  id += 1;
  return { cuota, id, fecha, descripcion, capital, interes, mora, multa, iva, cargos, total, saldo };
}
const useStyles = makeStyles({
  tableCell: {
    padding: "0px 0px 0px 0px",
    margin: 0
  }
});
function Computertable({ data, type }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);


  useEffect(() => {
    setRows([]);
    for (const property in data) {
      setRows(rows => [...rows, createData((type === 'loan' ? data[property].number : ''), data[property].date, data[property].interest, data[property]['level-fee'], data[property].iva, data[property]['fee-to-pay'], data[property]['capital-balance'], data[property].capital, data[property].adminC, data[property].total, data[property].remaining)]);
    }
  }, [data]);

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {type === 'loan' ? <TableCell className={classes.tableCell} align="center" style={{ width: '1%' }}>NO.</TableCell> : <></>}
            <TableCell className={classes.tableCell} align="center" style={{ width: '10%' }}>FECHA</TableCell>
            <TableCell className={classes.tableCell} align="center" style={{ width: '14%' }}>DESCRIPCIÓN</TableCell>
            <TableCell className={classes.tableCell} align="center" style={{ width: '10%' }}>CAPITAL</TableCell>
            <TableCell className={classes.tableCell} align="center" style={{ width: '9%' }}>INTERESES</TableCell>
            <TableCell className={classes.tableCell} align="center" style={{ width: '8%' }}>MORA</TableCell>
            <TableCell className={classes.tableCell} align="center" style={{ width: '8%' }}>MULTA</TableCell>
            <TableCell className={classes.tableCell} align="center" style={{ width: '10%' }}>IVA</TableCell>
            <TableCell className={classes.tableCell} align="center" style={{ width: '8%' }}>CARGOS ADMIN.</TableCell>
            <TableCell className={classes.tableCell} align="center" style={{ width: '10%' }}>TOTAL CUOTA</TableCell>
            <TableCell className={classes.tableCell} align="center" style={{ width: '12%' }}>SALDO CAPITAL </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              {type === 'loan' ? <TableCell align="right">{row.cuota}</TableCell> : <></>}
              <TableCell align="right">{row.fecha.length === 0 || row.fecha == '-' ? row.fecha : new Date(row.fecha.split('-')[1] + '/' + row.fecha.split('-')[0] + '/' + row.fecha.split('-')[2]).toLocaleDateString('en-GB', { year: '2-digit', month: '2-digit', day: '2-digit' })}</TableCell>
              <TableCell align="center">{row.descripcion}</TableCell>
              {row.capital.black == null ? <TableCell align="right" style={{ color: 'red' }}>{row.capital.red}</TableCell> : <TableCell align="right">{row.capital.black}</TableCell>}
              {row.interes.black == null ? <TableCell align="right" style={{ color: 'red' }}>{row.interes.red}</TableCell> : <TableCell align="right">{row.interes.black}</TableCell>}
              {row.mora.black == null ? <TableCell align="right" style={{ color: 'red' }}>{row.mora.red}</TableCell> : <TableCell align="right">{row.mora.black}</TableCell>}
              {row.multa.black == null ? <TableCell align="right" style={{ color: 'red' }}>{row.multa.red}</TableCell> : <TableCell align="right">{row.multa.black}</TableCell>}
              {row.iva.black == null ? <TableCell align="right" style={{ color: 'red' }}>{row.iva.red}</TableCell> : <TableCell align="right">{row.iva.black}</TableCell>}
              {row.cargos.black == null ? <TableCell align="right" style={{ color: 'red' }}>{row.cargos.red}</TableCell> : <TableCell align="right">{row.cargos.black}</TableCell>}
              {row.total.black == null ? <TableCell align="right" style={{ color: 'red' }}>{row.total.red}</TableCell> : <TableCell align="right">{row.total.black}</TableCell>}
              {row.saldo.black == null ? <TableCell align="right" style={{ color: 'red' }}>{row.saldo.red}</TableCell> : <TableCell align="right">{row.saldo.black}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}


export default withStyles(styles)(Computertable);
