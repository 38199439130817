import React, { useState } from 'react';
import MDTypography from "components/MDTypography";


export default function data(props) {
  if (props.length == 0) {
    return (<></>);
  }

  const t = [];
  const c = [];
  Object.keys(props).forEach(key => {
    const jsonRow = {};
    if (key === 'capitalade') {
      jsonRow['description'] = 'Capital adeudado';
    } else if (key === 'capitalapa') {
      jsonRow['description'] = '(+) Capital a pagar incluido en saldo pendiente';
    } else if (key === 'totalsal') {
      jsonRow['description'] = '(-) Total saldo pendiente ';
    } else if (key === 'totalade') {
      jsonRow['description'] = '(-) Total adelantado no aplicado';
    } else if (key === 'montocanc') {
      jsonRow['description'] = 'Monto para cancelar el crédito';
    }
    jsonRow['monto'] = (
      <MDTypography component="a" href="#" variant="caption" color={(key === 'totalsal' || key === 'totalade') ? 'error' : 'text'} fontWeight="medium">
        {(key === 'totalsal' || key === 'totalade' ? '-' : '') + props[key]}
      </MDTypography>
    );
    t.push(jsonRow);
  });

  c.push({ Header: "Descripción", accessor: "description", align: "left" });
  c.push({ Header: "Monto", accessor: "monto", align: "center" });

  return {
    columns: c,
    rows: t
  };
}
