import React, { useState } from 'react';

import MDTypography from "components/MDTypography";

export default function data(props) {
  if (props.length == 0) {
    return (<></>);
  }
  const t = [];
  const c = [];
  Object.values(props['payment-fees']).forEach(val => {
    const jsonRow = {};
    Object.keys(val).forEach(key => {
      if (val[key].length > 0 || Object.prototype.hasOwnProperty.call(val[key], 'black') || Object.prototype.hasOwnProperty.call(val[key], 'red')) {
        if (key === 'date' || (key === 'number' && props.type === 'loan')) {
          jsonRow[key] = val[key];
        } else if (key === 'diff') {
          jsonRow[key] = (
            <MDTypography component="a" href="#" variant="caption" color={Object.prototype.hasOwnProperty.call(val[key], 'black') ? 'text' : 'error'} fontWeight="medium">
              {Object.prototype.hasOwnProperty.call(val[key], 'black') ? val[key].black : val[key].red}
            </MDTypography>
          );
        } else {
          jsonRow[key] = (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {val[key]}
            </MDTypography>
          );
        }
      } else {
        jsonRow[key] = '';
      }
    });
    t.push(jsonRow);
  });

  if (props.type === 'loan') {
    c.push({ Header: "No. Cuota", accessor: "number", align: "left" });
  }
  c.push({ Header: "Fecha", accessor: "date", align: "left" });
  c.push({ Header: "Capital", accessor: "capital", align: "center" });
  c.push({ Header: "Intereses", accessor: "interest", align: "center" });
  c.push({ Header: "Mora", accessor: "late_fee", align: "center" });
  c.push({ Header: "Multa", accessor: "penalty_fee", align: "center" });
  c.push({ Header: "IVA", accessor: "iva", align: "center" });
  c.push({ Header: "Cargos Admin.", accessor: "administrative", align: "center" });
  c.push({ Header: "Total cuota", accessor: "totalT", align: "center" });
  c.push({ Header: "Saldo pagado", accessor: "capital-balance", align: "center" });
  c.push({ Header: "Saldo restante", accessor: "diff", align: "center" });
  c.push({ Header: "Días atrasados", accessor: "days", align: "center" });

  return {
    columns: c,
    rows: t
  };
}
