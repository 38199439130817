
import Grid from "@mui/material/Grid";
import Swal from 'sweetalert2'

import { useEffect, useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDTypography from "components/MDTypography";

import Button from '@mui/material/Button';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ListAltIcon from '@mui/icons-material/ListAlt';



import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import axios from 'axios';
import externalUrl from '../../config/externalUrl';
import Information from "./information";
import Status from "./status";

import Pendings from "./pendings";
import BounceLoader from "react-spinners/BounceLoader";
import Alert from '@mui/material/Alert';

function Dashboard() {
  const [loans, setLoans] = useState({ loaded: false, data: [] });
  const [information, setInformation] = useState({});
  const [status, setStatus] = useState({});
  const [plan, setPlan] = useState({});
  const [pendings, setPendings] = useState({});
  const [loan, setLoan] = useState("");
  const [mora, setMora] = useState(0);
  const [rate, setRate] = useState(0);
  const [creditos, setCreditos] = useState(0);



  const [user] = useState(localStorage.getItem('userData') === null ? {} : JSON.parse(localStorage.getItem('userData')));

  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);

  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    width: '30vw',
    showConfirmButton: false,
    background: '#1D565F',
    color: '#FFFFFF',
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  useEffect(() => {

    if (localStorage.getItem("userData") === null) { return; }

    try {
      const token = localStorage.getItem("userData") === null ? { private_token: null } : JSON.parse(localStorage.getItem('userData'));
      axios.get(`${externalUrl.borrowerUrl}/v1/borrower/get-loans`, {
        headers: { Authorization: `Bearer ${token.private_token}`, },
      }).then(function (response) {
        if (response.data.error === 1) {
          alert(response.data.msg);
          return;
        }
        if (response.data.msg.length > 0) {
          setInformation(response.data.msg[0].info);
          setStatus(response.data.msg[0].status);
          setMora(response.data.msg[0].late);
          setRate(response.data.msg[0].rate);
          setPlan(response.data.msg[0].plan);
          setPendings(response.data.msg[0].pending);
          setLoan(response.data.msg[0].display_name);
          setSelectedIndex(response.data.msg[0].id);
          setCreditos(response.data.msg.length);
          
        }

        setLoans({ loaded: true, data: response.data.msg });



      }).catch(() => {
        setLoans({ ...loans, loaded: true });
      }
      );
    } catch (e) {
    }

  }, []);



  const handleClick2 = (event) => {
    print('LETTER');
  };

  const handleClick3 = (event) => {

    const jsonText = plan.data;

    if(jsonText==null){
      Toast.fire({
        icon: 'info',
        title: "Su cuenta no tiene un plan de pagos activo."
      })
    }else{

    const json = JSON.parse(jsonText);
    delete json.type;

      const body = {
        xml: `<report>${OBJtoXML(json)}</report>`,
        format: 'LETTER'
      };
      axios.post(`${externalUrl.borrowerUrl}/v1/api/print-payment-plan`, body, {
        headers: { Authorization: `Bearer ${user.private_token}`, },
      }).then(function (response) {
        const pdfWindow = window.open("")
        pdfWindow.document.write(
          '<body style="overflow: hidden; margin: 0; padding: 0">' +
          "<iframe width='100%' height='100%' width='-webkit-fill-available' height='100%' height='-webkit-fill-available' type='application/pdf' src='data:application/pdf;base64, " +
          encodeURI(response.data) + "'></iframe></body>"
        )
      }).catch(function (e) {
        console.log(e)
      });
    }

  };



  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const [type, setType] = useState(1);

  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, ide) => {

    const result = loans.data.find(({ id }) => id === ide);
    setInformation(result.info);
    setStatus(result.status);
    setMora(result.late);
    setRate(result.rate);
    setPendings(result.pending);
    setLoan(result.display_name);
    setPlan(result.plan);

    setSelectedIndex(ide);
    setAnchorEl(null);
  };

  const handleTypeButtonSolicitar = (event) => {
    const result = loans.data.find(({ id }) => id === selectedIndex);
    const body = {
      id: result.id,
    };
    axios.post(`${externalUrl.borrowerUrl}/v1/api/send-request`, body, {
      headers: { Authorization: `Bearer ${user.private_token}`, },
    }).then(function (response) {
      Toast.fire({
        icon: response.data.error == 1 ? ' info' : 'success',
        title: response.data.msg
      })
    }).catch(function (e) {
      console.log(e)
    });

  };

  const handleTypeButton = (event) => {
    event.preventDefault();
    if (type === 1) {
      setType(2);
    } else {
      setType(1);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function OBJtoXML(obj) {
    var xml = '';
    for (var prop in obj) {
      xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
      if (obj[prop] instanceof Array) {
        for (var array in obj[prop]) {
          xml += "<" + prop + ">";
          xml += OBJtoXML(new Object(obj[prop][array]));
          xml += "</" + prop + ">";
        }
      } else if (typeof obj[prop] == "object") {
        xml += OBJtoXML(new Object(obj[prop]));
      } else {
        xml += obj[prop];
      }
      xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
    }
    var xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
    return xml
  }

  const print = (format) => {
    const jsonText = type == 1 ? status.data : pendings.data;
    const json = JSON.parse(jsonText);
    const typeLoan = json.type;
    delete json.type;

    Object.values(json['payment-fees']).forEach(val => {
      Object.keys(val).forEach(key => {
        if (val[key].length == 0) {
          val[key] = { black: ' ' };
        }
      });
    });

    const body = {
      xml: `<report>${OBJtoXML(json)}</report>`,
      type: type,
      report: typeLoan,
      format: format
    };
    axios.post(`${externalUrl.borrowerUrl}/v1/api/print-account-status`, body, {
      headers: { Authorization: `Bearer ${user.private_token}`, },
    }).then(function (response) {
      const pdfWindow = window.open("")
      pdfWindow.document.write(
        '<body style="overflow: hidden; margin: 0; padding: 0">' +
        "<iframe width='100%' height='100%' width='-webkit-fill-available' height='100%' height='-webkit-fill-available' type='application/pdf' src='data:application/pdf;base64, " +
        encodeURI(response.data) + "'></iframe></body>"
      )
    }).catch(function (e) {
      console.log(e)
    });
  }

  const iconButtonStyles = {

    color: "#1D565F"
  };


  if (!loans.loaded) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <div style={{ position: 'absolute', top: '1200%', left: '48%' }}>
          <BounceLoader color='#1D565F' loading={true} size={60} />
        </div>
      </DashboardLayout>
    )
  }

  return (

    <DashboardLayout>
      <DashboardNavbar />
      <br />
      <br />
      <MDTypography variant="h2">
        {loan}&nbsp;&nbsp;
        {creditos > 1 ?
          <Button style={{ color: '#FFFFFF', backgroundColor: '#1D565F' }} size="small" onClick={handleClickListItem}>
            Cambiar Préstamo
          </Button>
          : null}
      </MDTypography>



      <div>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          {loans.data.map((option, index) => (
            <MenuItem
              key={option.id}
              disabled={option.id === selectedIndex}
              selected={option.id === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, option.id)}
            >
              {option.display_name}
            </MenuItem>
          ))}
        </Menu>
      </div>

      <Information info={information} rate = {rate} mora = {mora} />



      <Grid container spacing={3} >
        <Grid item xs={12} md={6} lg={3} >
          <MDBox mb={1.5} >
            <Button classsName='general-buttom-dashboard' style={{ color: '#FFFFFF', width: '100%', backgroundColor: '#1D565F' }} variant="contained" startIcon={<LocalPrintshopIcon />}
              aria-controls={open2 ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open2 ? 'true' : undefined}
              onClick={handleClick2}
            /* onClick={() => print()} */>
              Imprimir {type === 1 ? "Estado de cuenta" : "Cuotas pendientes"}
            </Button>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3} >
          <MDBox mb={1.5}  >
            <Button style={{ color: '#FFFFFF', width: '100%', backgroundColor: '#1D565F' }} variant="contained" startIcon={<ListAltIcon />} onClick={(event) => handleTypeButton(event)} >
              {type !== 1 ? "Estado de cuenta" : "Cuotas pendientes"}
            </Button>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3} >
          <MDBox mb={1.5}  >
            <Button style={{ color: '#FFFFFF', width: '100%', backgroundColor: '#1D565F' }} variant="contained" startIcon={<ListAltIcon />} onClick={(event) => handleTypeButtonSolicitar(event)} >
              Solicitar {type === 1 ? "Estado de cuenta" : "Cuotas pendientes"} a la fecha
            </Button>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3} >
          <MDBox mb={1.5}  >
            <Button style={{ color: '#FFFFFF', width: '100%', backgroundColor: '#1D565F' }} variant="contained" startIcon={<LocalPrintshopIcon />}
              onClick={handleClick3} >
              Imprimir Plan de pagos
            </Button>
          </MDBox>
        </Grid>
      </Grid>

      {mora == 1 ? <Alert severity="warning" variant="outlined">Por encontrarse el estado de cuenta atrasado la cuota a pagar dependerá de la fecha en la que se realice el pago, por favor contáctenos para enviarle ese dato.</Alert> : null}


      <MDBox className='general-dashboard' pt={6} pb={3}>
        <Grid container spacing={6}>
          {type === 1 ?
              <Status info={status} />
            :
              <Pendings info={pendings} />
          }
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
