/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import externalUrl from '../../../config/externalUrl';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
} from "context";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode } = controller;
  const [user] = useState(localStorage.getItem('userData') === null ? '' : JSON.parse(localStorage.getItem('userData')).display_name);


  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toIdentity = () => {
    setAnchorEl(null);
    window.location.replace(`${externalUrl.identityUrl}`);
  };

  const logout = () => {
    setAnchorEl(null);
    localStorage.removeItem('userData');
    window.location.replace(`${externalUrl.borrowerUrl}/v1/auth/logout`);
  }

  return (
    <AppBar
      color="inherit"
      style={{ boxShadow: 'rgb(0, 0, 0) 0px 0px 4px 0px' }}
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)} className='navbar-general'>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} className='images-navbar-general' sx={(theme) => navbarRow(theme, { isMini })}>
          <a className="navbar-brand" href="/">
            <img
              src="https://www.alapar.com.gt/themes/glifos/images/svg/logo_top_verde.svg"
              alt=""
              width="30"
              height="30"
              className="d-inline-block align-middle logo-alapar-img"
              style={{ marginBottom: '-0.5rem' }}
            /> &nbsp;
            <img
              src="https://www.alapar.com.gt/themes/glifos/images/svg/logo_bottom_verde.svg"
              alt=""
              className="ms-2 d-inline-block align-middle text-alapar-img"
            />
          </a>
        </MDBox>

        <MDBox color="inherit">
          <Button
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            color="black"
            endIcon={<KeyboardArrowDownIcon />}
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            {user}
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={toIdentity}>Cuenta</MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
