const url = window.location.host;
var externalUrl = {};

if (url === 'dev.borrower.alapar.local' || url === 'localhost:3000') {
    externalUrl.identityUrl = 'https://dev.login.alapar.local';
    externalUrl.borrowerUrl = 'https://dev.app.borrower.alapar.local';
} else if (url === 'borrower.qa.alapar.com.gt') {
    externalUrl.identityUrl = 'https://login.qa.alapar.com.gt';
    externalUrl.borrowerUrl = 'https://app.borrower.qa.alapar.com.gt';
} else if (url === 'borrower.alapar.com.gt') {
    externalUrl.identityUrl = 'https://login.alapar.com.gt';
    externalUrl.borrowerUrl = 'https://app.borrower.alapar.com.gt';
}


module.exports = externalUrl;
