
import Grid from "@mui/material/Grid";



import MDBox from "components/MDBox";


import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";




function Information({ info, rate, mora }) {

  
  const data = JSON.parse(info.data);
  console.log(data);

  return (
    <MDBox py={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="ALAPAR"
              icon="paid"
              title="Monto autorizado"
              count={data == null ? '0' : (data.currency === 'USD' ? '$ ' : 'Q ') + (+data.authorized_amt).toLocaleString('en-US', { minimumFractionDigits: 2 })}
            />
          </MDBox>
        </Grid>
        <Grid item xs={16} md={6} lg={3}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              icon="today"
              title="Plazo"
              count={data == null ? '0' : data.total_payments + ' meses'}
              color="ALAPAR"
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="ALAPAR"
              icon="today"
              title="Fecha de desembolso"
              count={data == null ? '0' : new Date(data.start_date).toLocaleDateString('en-GB')}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5} >
            <ComplexStatisticsCard
              icon="notifications"
              title="Estado"
              count={parseFloat(rate).toFixed(4)+'%'}
              percentage={{ color: (mora != 1  ?  "success" :  "error"), amount: data == null ? '0' : (mora != 1  ?  "Al día" :  "Atrasado") }}
              color="ALAPAR"
            />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Information;
