import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";

import MDTypography from "components/MDTypography";
import accountStatusData from "layouts/tables/data/accountStatusData";
import Computertable from "./status/Computertable";
import MobileTable from "./status/MobileTable";
import IpadTable from "./status/IpadTable";

function Status({ info }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const breakpoint = 600;
  const breakpointIpad = 1000;

  const [data, setData] = useState(info === false ? null : JSON.parse(info.data));


  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    setData(info === false ? null : JSON.parse(info.data));
  }, [info]);

  return (
    <Grid item xs={12}>
      <Card>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          borderRadius="lg"
          coloredShadow="info"
          style={{ background: 'linear-gradient(195deg, #316E76, #1d575f)', boxShadow: '0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(49 110 118 / 40%)' }}
        >
          <MDTypography className='status-title' variant="h6" color="white">
            Estado de cuenta{data == null ? null : " al ("+data.datee+")"}
            {info === false ?
              null :
              width < breakpoint ?
              <span className="adelanto-text">{Object.prototype.hasOwnProperty.call(data, 'company') ? ` Adelanto a siguiente cuota: ${data.company.bank}` : ''}</span>
              :
              <span className="adelanto-text" style={{ right: '2rem', position: 'absolute' }}>{Object.prototype.hasOwnProperty.call(data, 'company') ? ` Adelanto a siguiente cuota: ${data.company.bank}` : ''}</span>
              
            }
          </MDTypography>
        </MDBox>
        <MDBox pt={3}>
          {
            info === false ?
            null
            :
            data['payment-fees'] == null ? <></> :
            Object.keys(data).length > 0 &&
                width < breakpoint ? <MobileTable data={data['payment-fees']} type={data.type} /> :
                width < breakpointIpad ? <IpadTable data={data['payment-fees']} type={data.type} /> :
                <Computertable data={data['payment-fees']} type={data.type} />
          }
        </MDBox>
      </Card>
    </Grid>
  );
}

export default Status;
