import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@mui/material/TableContainer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
  },
});

let id = 0;
function createData(cuota, fecha, capital, interes, mora, multa, iva, cargos, total, pagado, saldo, dias) {
  id += 1;
  return { cuota, id, fecha, capital, interes, mora, multa, iva, cargos, total, pagado, saldo, dias };
}

const useStyles = makeStyles({
  tableCell: {
    padding: "0px 0px 0px 0px",
    margin: 0
  }
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {props.type === 'loan' ?
          <TableCell component="th" scope="row" align="right" style={{ width: '1px', padding: '0' }}>{row.cuota}</TableCell>
          :
          <TableCell component="th" scope="row" align="right" style={{ width: '1px', padding: '0' }}>{row.fecha.length === 0 ? row.fecha : new Date(row.fecha.split('/')[1] + '/' + row.fecha.split('/')[0] + '/' + row.fecha.split('/')[2]).toLocaleDateString('en-GB', { year: '2-digit', month: '2-digit', day: '2-digit' })}</TableCell>
        }
        <TableCell align="right" style={{ width: '1px', padding: '0' }}>{row.pagado === 'TOTAL: ' || row.pagado === 'ADELANTO: ' ? row.pagado : row.total}</TableCell>
        {Object.prototype.hasOwnProperty.call(row.saldo, 'red') ? <TableCell align="right" style={{ color: 'red', width: '1px', padding: '0' }}>{row.saldo.red}</TableCell> : <TableCell align="right" style={{ width: '1px', padding: '0' }}>{row.saldo.black}</TableCell>}
        <TableCell>
          {
            row.pagado === 'TOTAL: ' || row.pagado === 'ADELANTO: ' ? <div style={{ height: '1rem' }}></div> :
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>

              <Table size="small" aria-label="purchases">
                {
                  props.type === 'loan' ?
                    <TableRow>
                      <TableCell className={classes.tableCell} style={{ width: '20%', height: '2rem' }} >FECHA</TableCell>
                      <TableCell className={classes.tableCell} style={{ width: '80%' }} align="right">{row.fecha.length === 0 ? row.fecha : new Date(row.fecha.split('/')[1] + '/' + row.fecha.split('/')[0] + '/' + row.fecha.split('/')[2]).toLocaleDateString('en-GB', { year: '2-digit', month: '2-digit', day: '2-digit' })}</TableCell>
                    </TableRow>
                    :
                    <></>
                }
                <TableRow>
                  <TableCell className={classes.tableCell} style={{ width: '20%' }}>CAPITAL</TableCell>
                  <TableCell align="right">{row.capital}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCell} style={{ width: '20%' }}>INTERESES</TableCell>
                  <TableCell align="right">{row.interes}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCell} style={{ width: '20%' }}>MORA</TableCell>
                  <TableCell align="right">{row.mora}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCell} style={{ width: '20%' }}>MULTA</TableCell>
                  <TableCell align="right">{row.multa}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCell} style={{ width: '20%' }}>IVA</TableCell>
                  <TableCell align="right" >{row.iva}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCell} style={{ width: '50%' }}>CARGOS ADMINISTRATIVOS</TableCell>
                  <TableCell align="right">{row.cargos}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCell} style={{ width: '30%' }} >DÍAS ATRASADOS</TableCell>
                  <TableCell align="right">{row.dias}</TableCell>
                </TableRow>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

function MobileTable({ data, type }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows([]);
    for (const property in data) {
      setRows(rows => [...rows, createData(data[property].number, data[property].date, data[property].capital, data[property].interest, data[property].late_fee, data[property].penalty_fee, data[property].iva, data[property].administrative, data[property].totalT, data[property]['capital-balance'], data[property].diff, data[property].days)]);
    }
  }, [data]);

  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell} align="center" style={{ width: '9%', paddingLeft: '0.5rem' }}>{type === 'loan' ? 'NO.' : 'FECHA'}</TableCell>
            <TableCell className={classes.tableCell} align="center" style={{ width: '45%' }}>TOTAL</TableCell>
            <TableCell className={classes.tableCell} align="center" style={{ width: '45%' }}>SALDO RESTANTE</TableCell>
            <TableCell className={classes.tableCell} align="right" style={{ width: '1%' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={'row_ec_' + row.id} row={row} type={type} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export default withStyles(styles)(MobileTable);