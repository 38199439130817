import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@mui/material/TableContainer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
  },
});


let id = 0;
function createData(cuota, fecha, descripcion, capital, interes, mora, multa, iva, cargos, total, saldo) {
  id += 1;
  return { cuota, id, fecha, descripcion, capital, interes, mora, multa, iva, cargos, total, saldo };
}

const useStyles = makeStyles({
  tableCell: {
    padding: "0px 0px 0px 0px",
    margin: 0
  }
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row" align="center" style={{ width: '1px', padding: '0' }}>
          {
            props.type === 'loan' ?
              row.cuota
              :
              row.fecha == '-' ? '-' : new Date(row.fecha.split('-')[1] + '/' + row.fecha.split('-')[0] + '/' + row.fecha.split('-')[2]).toLocaleDateString('en-GB', { year: '2-digit', month: '2-digit', day: '2-digit' })
          }
        </TableCell>
        <TableCell align={(row.total.black === 'Saldo inicial') ? "right" : "center"} style={{ width: '1px', padding: '0' }}>{row.descripcion}</TableCell>
        {row.total.black == null ? <TableCell align="right" style={{ color: 'red', width: '1px', padding: '0' }}>{row.total.red}</TableCell> : <TableCell align="right" style={{ width: '1px', padding: '0' }}>{row.total.black}</TableCell>}
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {
                row.total.black === 'Saldo inicial' ?
                  <Table size="small" aria-label="purchases">
                    <TableRow>
                      <TableCell className={classes.tableCell} style={{ width: '20%' }} >SALDO CAPITAL</TableCell>
                      {row.saldo.black == null ? <TableCell align="right" style={{ color: 'red' }} >{row.saldo.red}</TableCell> : <TableCell align="right">{row.saldo.black}</TableCell>}
                    </TableRow>
                  </Table>
                  : (
                    <Table size="small" aria-label="purchases">
                      {props.type === 'loan' ?
                        <TableRow>
                          <TableCell className={classes.tableCell} style={{ width: '20%', height: '1.5rem' }} >FECHA</TableCell>
                          <TableCell className={classes.tableCell} style={{ width: '80%' }} align="right">{new Date(row.fecha.split('-')[1] + '/' + row.fecha.split('-')[0] + '/' + row.fecha.split('-')[2]).toLocaleDateString('en-GB', { year: '2-digit', month: '2-digit', day: '2-digit' })}</TableCell>
                        </TableRow>
                        :
                        <></>
                      }
                      <TableRow>
                        <TableCell className={classes.tableCell} style={{ width: '20%' }}>CAPITAL</TableCell>
                        {row.capital.black == null ? <TableCell align="right" style={{ color: 'red' }}>{row.capital.red}</TableCell> : <TableCell align="right">{row.capital.black}</TableCell>}
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell} style={{ width: '20%' }}>INTERESES</TableCell>
                        {row.interes.black == null ? <TableCell align="right" style={{ color: 'red' }}>{row.interes.red}</TableCell> : <TableCell align="right">{row.interes.black}</TableCell>}
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell} style={{ width: '20%' }}>MORA</TableCell>
                        {row.mora.black == null ? <TableCell align="right" style={{ color: 'red' }}>{row.mora.red}</TableCell> : <TableCell align="right">{row.mora.black}</TableCell>}
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell} style={{ width: '20%' }}>MULTA</TableCell>
                        {row.multa.black == null ? <TableCell align="right" style={{ color: 'red' }}>{row.multa.red}</TableCell> : <TableCell align="right">{row.multa.black}</TableCell>}
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell} style={{ width: '20%' }}>IVA</TableCell>
                        {row.iva.black == null ? <TableCell align="right" style={{ color: 'red' }}>{row.iva.red}</TableCell> : <TableCell align="right" >{row.iva.black}</TableCell>}
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell} style={{ width: '50%' }}>CARGOS ADMINISTRATIVOS</TableCell>
                        {row.cargos.black == null ? <TableCell align="right" style={{ color: 'red' }}>{row.cargos.red}</TableCell> : <TableCell align="right">{row.cargos.black}</TableCell>}
                      </TableRow>
                      {
                        row.saldo.length === 0 ?
                          <></>
                          :
                          <TableRow>
                            <TableCell className={classes.tableCell} style={{ width: '20%' }} >SALDO CAPITAL</TableCell>
                            {row.saldo.black == null ? <TableCell align="right" style={{ color: 'red' }} >{row.saldo.red}</TableCell> : <TableCell align="right">{row.saldo.black}</TableCell>}
                          </TableRow>
                      }
                    </Table>
                  )
              }

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

function MobileTable({ data, type }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows([]);
    for (const property in data) {
      setRows(rows => [...rows, createData(data[property].number, data[property].date, data[property].interest, data[property]['level-fee'], data[property].iva, data[property]['fee-to-pay'], data[property]['capital-balance'], data[property].capital, data[property].adminC, data[property].total, data[property].remaining)]);
    }
  }, [data]);

  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell} align="center" style={{ width: '20%' }}>{type === 'loan' ? 'NO.' : 'FECHA'}</TableCell>
            <TableCell className={classes.tableCell} align="center" style={{ width: '45%' }}>DESCRIPCIÓN</TableCell>
            <TableCell className={classes.tableCell} align="center" style={{ width: '45%' }}>TOTAL CUOTA</TableCell>
            <TableCell className={classes.tableCell} align="right" style={{ width: '1%' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.id} row={row} type={type} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default withStyles(styles)(MobileTable);