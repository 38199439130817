import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";

import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import pendingInstallmentsData from "layouts/tables/data/pendingInstallmentsData";
import creditCancelData from "layouts/tables/data/creditCancelData";

import Computertable from "./pendings/Computertable";
import MobileTable from "./pendings/MobileTable";
import IpadTable from "./pendings/IpadTable";
import CreditCancelTable from "./pendings/CreditCancelTable";

function Pendings({ info }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const breakpoint = 600;

  const data = info === false ? null : JSON.parse(info.data);

  const { columns: pColumns, rows: pRows } = info === false ? [] : pendingInstallmentsData(Object.keys(data).length === 0 ? [] : data);
  const { columns: cColumns, rows: cRows } = info === false ? [] : creditCancelData(data.hasOwnProperty('creditCancel') ? data.creditCancel : []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [data]);

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            style={{ background: 'linear-gradient(195deg, #316E76, #1d575f)', }}
          >
            <MDTypography variant="h6" color="white">
              Cuotas pendientes{data == null ? null : " al ("+data.datee+")"}
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            {
              info === false ?
                null
                :
                data['payment-fees'] == null ? <></> :
                Object.keys(data).length === 0 ? <></> :
                  width < breakpoint ? <MobileTable data={data['payment-fees']} type={data.type} /> :
                    width < 1000 ? <IpadTable data={data['payment-fees']} type={data.type} /> :
                      <Computertable data={data['payment-fees']} type={data.type} />
            }
          </MDBox>
        </Card>
      </Grid>
      {info === false ? null : data.hasOwnProperty('creditCancel') ?
        <>
          {width < 1000 ? <></> : <Grid item xs={3} />}
          <Grid item xs={width < 1000 ? 12 : 6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ background: 'linear-gradient(195deg, #316E76, #1d575f)', }}
              >
                <MDTypography variant="h6" color="white">
                  Total para cancelar crédito
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {
                  info === false ?
                    null
                    :
                    <CreditCancelTable data={data.creditCancel} />
                  /* <DataTable
                    table={{ columns: cColumns, rows: cRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  /> */
                }
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={3} />
        </>
        : <></>}

    </>
  );
}

export default Pendings;
/* <DataTable
  table={{ columns: pColumns, rows: pRows }}
  isSorted={false}
  entriesPerPage={false}
  showTotalEntries={false}
  noEndBorder
/> */